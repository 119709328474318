import { pushOrderStartToGTM } from '../CTALink';
import routesMap from '../../Routes';

export const getServicesList = (orderRoute, restart) => ([
  {
    text: 'Réserver',
    url: orderRoute,
    onClick: () => pushOrderStartToGTM(orderRoute, 'MenuBarServices', restart),
  },
  { text: 'Besoin d’aide ?', url: routesMap.FAQPage.url },
  { text: 'Comment ça marche', url: routesMap.HowItWorksPage.url },
  { text: 'Notre histoire', url: routesMap.AboutPage.url },
  { text: 'Nos boutiques', url: routesMap.Retail.url },
  { text: 'Nos artisans', url: routesMap.OurArtisansPage.url },
  { text: 'Devenir Tilliste', url: routesMap.BeTillistePage.url },
  { text: 'Achat de tissu', url: routesMap.HomePageTilliUptrade.url },
]);

export const partnersList = [
  { text: 'Nos événements', url: routesMap.EventsPage.url },
  { text: 'Nos partenaires', url: routesMap.PartnersPage.url },
  { text: 'Devenir partenaire', url: routesMap.WhyBecomePartner.url },
];

export const inspirationsList = [
  { text: 'Mode Durable', url: routesMap.SustainableFashionPage.url },
  { text: 'Lookbook', url: routesMap.LookBookPage.url },
  { text: 'Les inspirations', url: routesMap.LaRubrique.url },
];
