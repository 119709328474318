import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';

import { pushOrderStartToGTM } from '../../CTALink';
import withCustomerContext from '../../../withCustomerContext';
import withOrderContext from '../../../withOrderContext';
import dropDownArrow from '../../../assets/arrow-dropdown-list.svg';
import routesMap from '../../../Routes';
import { removeToken } from '../../../services/api';
import {
  colors, fontWeight, margins, mobileThresholdPixels, Content,
  menuBarHeightPixels, mobileMenuBarHeightPixels,
} from '../v2/styledComponents';
import { fontSizes } from './styledComponents';
import FooterMobileAndSocial from './FooterMobileAndSocial';
import { getServicesList, partnersList, inspirationsList } from '../menuData';

const getHeaderContent = (orderRoute, restart) => ([
  {
    index: 0,
    title: 'Service',
    links: getServicesList(orderRoute, restart),
  },
  {
    index: 1,
    title: 'Grille des tarifs',
    url: routesMap.PricingsPage.url,
  },
  {
    index: 2,
    title: 'Collabs',
    links: [
      { text: 'Tout voir', url: routesMap.CollabPage.url },
      ...partnersList,
    ],
  },
  {
    index: 3,
    title: 'Blog',
    links: inspirationsList,
  },
  {
    index: 4,
    title: 'Connexion',
    links: [
      { text: 'Récapitulatif', slug: 'MyAccount' },
      { text: 'Mes informations', slug: 'MyInfos' },
      { text: 'Mes adresses & paiements', slug: 'MyAddressesAndPayments' },
      { text: 'Mes commandes', slug: 'MyOrders' },
      { text: 'Déconnexion', slug: 'Logout' },
    ],
  },
  {
    index: 5,
    title: 'Prendre rendez-vous',
    url: orderRoute,
    onClick: () => pushOrderStartToGTM(orderRoute, 'MobileMenu', restart),
    isYellow: true,
  },
]);

const StyledContent = styled(Content)`
  padding: 0px ${margins.s} 100px;
  background-color: ${colors.white};
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: calc(100vh - ${menuBarHeightPixels} - 100px);
  overflow: scroll;
  z-index: 2;
  width: auto;
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    height: calc(100vh - ${mobileMenuBarHeightPixels} - 100px);
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  text-align: center;
`;

const TextContainer = styled.div`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: ${fontSizes.s};
  line-height: 24px;
  text-align: left;
  font-weight: ${fontWeight.regular};
  margin-left: 20px;
  ${(props) => props.link && `padding-bottom: ${margins.s};`}
`;

const MenusContainer = styled.div`
  border-color: ${colors.navy};
  border-style: solid;
  border-width: 1px 0px 0px;
  margin: 15px 45px 30px;
  padding: 0px;
`;

const MenuContainer = styled.div`
  margin: 0px;
  border-color: ${colors.navy};
  border-style: solid;
  border-width: 0px 0px 1px;
`;

const MenuTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MenuTitle = styled.h3`
  margin: 20px 0px;
  color: ${({ isYellow }) => isYellow ? colors.yellow : colors.navy};
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: ${fontSizes.s};
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
`;

const Img = styled.img`
  padding-bottom: 1px;
  margin-left: 4px;
  width: 10px;
  ${(props) => props.rotate && 'transform: rotate(180deg)'};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

class MobileMenu extends React.Component {
  constructor() {
    super();
    this.state = { openedIndex: -1 };
  }

  clickAndClose(onClick) {
    const { close } = this.props;
    close();
    onClick();
  }

  goOnSelectedAccountCategory(categorySlug) {
    const {
      close,
      customerContext: { setSelectedOrder, initializeCustomerContext },
    } = this.props;
    close();
    if (categorySlug === 'Logout') {
      initializeCustomerContext();
      removeToken();
      navigate(routesMap.HomePage.url);
    } else {
      if (categorySlug === 'MyOrders') {
        setSelectedOrder(undefined);
      }
      navigate(routesMap[categorySlug].url);
    }
  }

  renderUrlContent = (content) => {
    const { onClick } = this.props;
    return (
      <Link
        key={content.url}
        style={{ textDecoration: 'none', color: 'inherit' }}
        to={content.url}
        onClick={() => !content.onClick ? onClick() : this.clickAndClose(content.onClick)}
      >
        <MenuTitle isYellow={content.isYellow}>{content.title}</MenuTitle>
      </Link>
    );
  }

  renderLoginContent = (content) => {
    const { onClick, openLoginPopup } = this.props;
    return (
      <MenuTitle
        onClick={() => {
          openLoginPopup();
          onClick();
        }}
      >
        {content.title}
      </MenuTitle>
    );
  }

  renderMyAccountContent = (index) => {
    const { openedIndex } = this.state;
    return (
      <Row
        onClick={() => this.setState({ openedIndex: openedIndex === index ? -1 : index })}
      >
        <MenuTitle>Mon compte</MenuTitle>
        <Img
          alt="Flèche menu déroulant"
          src={dropDownArrow}
          rotate={(openedIndex === index) ? 'true' : undefined}
        />
      </Row>
    );
  }

  renderRegularContent = (content, index) => {
    const { openedIndex } = this.state;
    return (
      <Row onClick={() => this.setState({ openedIndex: openedIndex === index ? -1 : index })}>
        <MenuTitle>
          {content.title}
        </MenuTitle>
        <Img
          alt="Flèche menu déroulant"
          src={dropDownArrow}
          rotate={(openedIndex === index) ? 'true' : undefined}
        />
      </Row>
    );
  }

  renderContent(content, index, isCustomerLoggedIn) {
    if (content.url) return this.renderUrlContent(content);
    if (content.title === 'Connexion') {
      if (!isCustomerLoggedIn) {
        return this.renderLoginContent(content);
      }
      return this.renderMyAccountContent(index);
    }
    return this.renderRegularContent(content, index);
  }

  renderHeaderContent = () => {
    const { isCustomerLoggedIn, onClick, orderContext: { getOrderRouteAndEvent } } = this.props;
    const { openedIndex } = this.state;
    const { orderRoute, restart } = getOrderRouteAndEvent();
    const headerContent = getHeaderContent(orderRoute, restart);
    return (
      headerContent.map((content, index) => (
        <MenuContainer key={`${content.title}_mobile`}>
          <MenuTitleContainer>
            {this.renderContent(content, index, isCustomerLoggedIn)}
          </MenuTitleContainer>
          {(openedIndex === index) && content.links.map((link) => {
            if (content.title === 'Connexion') {
              return (
                <TextContainer
                  key={link.text}
                  link
                  onClick={() => this.goOnSelectedAccountCategory(link.slug)}
                >
                  {link.text}
                </TextContainer>
              );
            }
            return (
              <Link
                key={link.url}
                style={{ textDecoration: 'none', color: 'inherit' }}
                to={link.url}
                onClick={() => {
                  onClick();
                  if (typeof link.onClick === 'function') link.onClick();
                }}
              >
                <TextContainer link>{link.text}</TextContainer>
              </Link>
            );
          })}
        </MenuContainer>
      ))
    );
  }

  render() {
    return (
      <StyledContent hasNoBanner>
        <SubContainer>
          <TextMainContainer>
            <MenusContainer>
              {this.renderHeaderContent()}
            </MenusContainer>
            <FooterMobileAndSocial inverse />
          </TextMainContainer>
        </SubContainer>
      </StyledContent>
    );
  }
}

MobileMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  openLoginPopup: PropTypes.func.isRequired,
  isCustomerLoggedIn: PropTypes.bool.isRequired,
  customerContext: PropTypes.shape({
    initializeCustomerContext: PropTypes.func,
    setSelectedOrder: PropTypes.func,
  }).isRequired,
  close: PropTypes.func,
  orderContext: PropTypes.shape({
    getOrderRouteAndEvent: PropTypes.func,
  }).isRequired,
};

MobileMenu.defaultProps = {
  close() {},
};

export default withCustomerContext(withOrderContext(MobileMenu));
