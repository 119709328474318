import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

const blink = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const DotsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  height: min-content;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 2.5px;
  }
`;

const P = styled.p`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin: auto ${(props) => props.spacing / 2}px;
  animation: ${css`${blink} 0.6s ease-in infinite alternate`};
  ${({ second }) => second && 'animation-delay: 0.2s;'}
  ${({ third }) => third && 'animation-delay: 0.4s;'}
`;

const Spinner = ({ color, size, spacing }) => (
  <DotsContainer>
    <P color={color} size={size} spacing={spacing} />
    <P color={color} size={size} spacing={spacing} second />
    <P color={color} size={size} spacing={spacing} third />
  </DotsContainer>
);

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  spacing: PropTypes.number,
};

Spinner.defaultProps = {
  color: colors.navy,
  size: 4,
  spacing: 11,
};

export default Spinner;
