import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import callApi from '../../services/api';
import OutInSideClick from '../home/v2/OutInSideClick';
import CustomerForm from '../order/CustomerForm';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';

const OpacityContainer = styled.div`
  background: ${colors.opacityFilter};
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  overflow: visible;
  width: 390px;
  margin: 0 auto;
  padding: 25px 50px 35px;

  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    padding: 22px 12px;
    margin-top: 100px;
    margin-bottom: 25px;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 26px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 22px;
  }
`;

const Button = styled(ButtonV3)`
  margin: 0px;
  width: 245px;
  ${({ marginTop }) => marginTop && 'margin-top: 5px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    min-width: unset;
    width: 205px;
  }
`;

const Text = styled.p`
  margin-top: 0px;
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 275px;
  }
`;

export const getForgottenPasswordInputs = () => ({
  email: {
    value: '', name: 'email', label: 'Adresse mail', isRequired: true, size: 'large', isMobileLarge: true,
  },
  errorEmail: { name: 'errorEmail', isActive: false, label: 'Adresse non valide' },
});

class PasswordForgottenModal extends Component {
  constructor() {
    super();
    this.state = {
      forgottenPasswordForm: getForgottenPasswordInputs(),
    };
    this.setFormInputValue = this.setFormInputValue.bind(this);
    this.checkFormInputValue = this.checkFormInputValue.bind(this);
    this.sendForgottenPasswordRequest = this.sendForgottenPasswordRequest.bind(this);
  }

  setFormInputValue(target) {
    const { name, value } = target;
    const { forgottenPasswordForm } = this.state;
    const form = { ...forgottenPasswordForm };
    form[name].value = value;
    this.setState({ forgottenPasswordForm: { ...form } });
  }

  checkFormInputValue(name) {
    const { forgottenPasswordForm } = this.state;
    const form = { ...forgottenPasswordForm };

    form[name].error = !form[name].value;
    this.setState({ forgottenPasswordForm: { ...form } });
  }

  sendForgottenPasswordRequest() {
    const { forgottenPasswordForm: { email } } = this.state;
    callApi('sendForgottenPasswordRequest', 'post', { email: email.value })
      .then(() => {
        this.setState({ isForgottenPasswordSent: true });
      })
      .catch(() => {
        const { forgottenPasswordForm: forgottenPasswordFormState } = this.state;
        const forgottenPasswordForm = { ...forgottenPasswordFormState };
        forgottenPasswordForm.errorEmail.label = 'Email non reconnu';
        forgottenPasswordForm.errorEmail.isActive = true;
        forgottenPasswordForm.email.error = true;
        this.setState({ forgottenPasswordForm: { ...forgottenPasswordForm }, isForgottenPasswordSent: false });
      });
  }

  render() {
    const {
      forgottenPasswordForm, isForgottenPasswordSent,
    } = this.state;

    const { hide } = this.props;

    return (
      <OpacityContainer>
        <OutInSideClick handleClickOutside={() => hide(true)}>
          <Container>
            <Title>Mot de passe oublié ?</Title>
            {!isForgottenPasswordSent
              && (
                <CustomerForm
                  inputsList={forgottenPasswordForm}
                  listName="forgottenPasswordForm"
                  checkFormInputValue={this.checkFormInputValue}
                  setFormInputValue={this.setFormInputValue}
                  compact
                />
              )}
            {isForgottenPasswordSent && <Text>Demande effectuée, un email vient de vous être envoyé 🙂</Text>}
            {!isForgottenPasswordSent
              && <Button navy marginTop onClick={this.sendForgottenPasswordRequest}>Envoyer</Button>}
            {isForgottenPasswordSent
              && <Button navy marginTop onClick={hide}>OK</Button>}
          </Container>
        </OutInSideClick>
      </OpacityContainer>
    );
  }
}

PasswordForgottenModal.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default PasswordForgottenModal;
