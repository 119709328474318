import getLevenshteinDistance from 'js-levenshtein';

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// cSpell: disable
const emailFrequentDomains = [
  'gmail.com',
  'hotmail.fr',
  'hotmail.com',
  'yahoo.fr',
  'orange.fr',
  'live.fr',
  'wanadoo.fr',
  'free.fr',
  'icloud.com',
  'yahoo.com',
  'outlook.fr',
  'me.com',
  'laposte.net',
  'sfr.fr',
  'msn.com',
  'tilli.fr',
  'outlook.com',
  'aol.com',
  'edhec.com',
  'neuf.fr',
  'noos.fr',
  'ymail.com',
  'mac.com',
  'essca.eu',
  'numericable.fr',
  'potionhq.com',
  'sezane.com',
  'thesocialitefamily.com',
  'hec.edu',
  'sciencespo.fr',
  'club-internet.fr',
  'ehl.ch',
];
// cSpell: enable

export const isEmailValid = (email) => EMAIL_REGEX.test(email);

export const getNormalizedString = (string = '') => (
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/-/g, '').toLowerCase()
);

export const getStringWithoutNumbers = (string) => (
  string.toLowerCase().replace(/[0-9]/g, '')
);

export const getFrequentNames = (firstname = '', lastname = '') => {
  const frequentNames = [];
  const newFirstname = getNormalizedString(firstname);
  const newLastname = getNormalizedString(lastname);
  frequentNames.push(`${newFirstname}${newLastname}`);
  frequentNames.push(`${newLastname}${newFirstname}`);
  frequentNames.push(`${newFirstname}.${newLastname}`);
  frequentNames.push(`${newLastname}.${newFirstname}`);
  frequentNames.push(`${newFirstname?.[0] || ''}${newFirstname?.[0] ? '.' : ''}${newLastname}`);
  frequentNames.push(`${newLastname?.[0] || ''}${newLastname?.[0] ? '.' : ''}${newFirstname}`);
  frequentNames.push(`${newFirstname?.[0] || ''}${newLastname}`);
  frequentNames.push(`${newLastname?.[0] || ''}${newFirstname}`);
  frequentNames.push(`${newFirstname}${newLastname?.[0] ? '.' : ''}${newLastname?.[0] || ''}`);
  frequentNames.push(`${newLastname}${newFirstname?.[0] ? '.' : ''}${newFirstname?.[0] || ''}`);
  frequentNames.push(`${newFirstname}${newLastname?.[0] || ''}`);
  frequentNames.push(`${newLastname}${newFirstname?.[0] || ''}`);
  return frequentNames;
};

export const getDistanceFromFrequentDomains = (emailDomain) => emailFrequentDomains.reduce(
  (distance, domain) => Math.min(distance, getLevenshteinDistance(domain, emailDomain)),
  getLevenshteinDistance(emailFrequentDomains[0], emailDomain),
);

export const getNearestFrequentDomain = (emailDomain) => emailFrequentDomains.reduce(
  (nearestFrequentDomain, domain, index) => {
    if (index === 0) return nearestFrequentDomain;
    const nearestFrequentDomainDistance = getLevenshteinDistance(nearestFrequentDomain, emailDomain);
    const distance = getLevenshteinDistance(domain, emailDomain);
    return nearestFrequentDomainDistance <= distance ? nearestFrequentDomain : domain;
  },
  emailFrequentDomains[0],
);

export const getNearestFrequentName = (emailName, firstname, lastname) => {
  const newEmailName = emailName.toLowerCase().replace(/[0-9]/g, '');
  const emailFrequentNames = getFrequentNames(firstname, lastname);

  return emailFrequentNames.reduce(
    (nearestFrequentName, name, index) => {
      if (index === 0) return nearestFrequentName;
      const nearestFrequentNameDistance = getLevenshteinDistance(nearestFrequentName, newEmailName);
      const distance = getLevenshteinDistance(name, newEmailName);
      return nearestFrequentNameDistance <= distance ? nearestFrequentName : name;
    },
    emailFrequentNames[0],
  );
};

export const getDistanceFromFrequentNames = (emailName, firstname, lastname) => {
  const emailFrequentNames = getFrequentNames(firstname, lastname);
  const newEmailName = getStringWithoutNumbers(emailName);

  return emailFrequentNames.reduce(
    (distance, name) => Math.min(distance, getLevenshteinDistance(name, newEmailName)),
    getLevenshteinDistance(emailFrequentNames[0], newEmailName),
  );
};

// is is called doesEmailLookValid and not isEmailValid because it detects typo mistakes in the email from a list of the most frequent domains
// but they may be voluntary (not mistakes)
export const doesEmailLookValid = (email, firstname, lastname) => {
  const emailName = email.split('@')[0];
  const emailDomain = email.split('@')[1];

  if (!emailDomain) return true;

  const distanceFromFrequentNames = getDistanceFromFrequentNames(emailName, firstname, lastname);
  const distanceFromFrequentDomains = getDistanceFromFrequentDomains(emailDomain);
  // it there are 1 or 2 typo in the domain
  if ([1, 2].includes(distanceFromFrequentDomains)
    || (distanceFromFrequentDomains === 0 && [1, 2].includes(distanceFromFrequentNames))) {
    return false;
  }

  return true;
};

export function isPhoneValid(phone) {
  if (phone) {
    const formattedPhone = phone.replace(/\./g, '').replace(/ /g, '');
    if (Number.isNaN(Number(formattedPhone))) return false;
    if (formattedPhone.startsWith('+')) {
      if (formattedPhone.startsWith('+33')) return formattedPhone.length === 12;
    } else {
      return formattedPhone.length === 10;
    }
    return true;
  }
  return false;
}

export const getValidEmail = (email, firstname, lastname) => {
  const emailName = email.value.split('@')[0];
  const emailDomain = email.value.split('@')[1];
  const distanceFromFrequentNames = getDistanceFromFrequentNames(emailName, firstname.value, lastname.value);
  const comparedEmailName = getStringWithoutNumbers(emailName);
  const emailNameLabel = [1, 2].includes(distanceFromFrequentNames)
    ? getNearestFrequentName(emailName, firstname.value, lastname.value)
    : comparedEmailName;
  const distanceFromFrequentDomains = getDistanceFromFrequentDomains(emailDomain);
  const emailDomainLabel = [1, 2].includes(distanceFromFrequentDomains) ? getNearestFrequentDomain(emailDomain) : emailDomain;
  const validEmail = `${emailName.replace(comparedEmailName, emailNameLabel)}@${emailDomainLabel}`;
  return validEmail;
};
