import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  mobileThresholdPixels, colors,
  FormInput as FormInputV2,
} from '../home/v3/styledComponents';
import Spinner from './Spinner';

import errorLogo from '../../assets/error.png';
import verifiedLogo from '../../assets/verified.png';

const formInputWidths = {
  small: { desktop: 143, mobile: 87 },
  medium: { desktop: 193, mobile: 124 },
  large: { desktop: 373, mobile: 258 },
};

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${({ compact }) => compact && 'flex-direction: column;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ compact }) => compact && 'align-items: center;'}
  }
`;

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ compact }) => compact && 'width: 275px;'}
  }
`;

const SubContainer = styled.div`display: flex`;

const FormInput = styled(FormInputV2)`
  margin: 0px ${({ error }) => error !== undefined ? '10' : '20'}px 20px 0px;
  border-radius: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-family: Roboto;
  font-size: 15px;
  outline: 0;
  border: solid 0.5px ${colors.navy};
  width: ${({ size }) => `${formInputWidths[size].desktop}`}px;
  color: ${colors.navy};
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px hsl(1, 100%, 100%) inset;
    -webkit-text-fill-color: ${colors.navy};
  };
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ isMobileLarge, size }) => (isMobileLarge || size !== 'small') && 'margin-right: 0px;'}
    width: ${({ isMobileLarge, size }) => isMobileLarge
    ? formInputWidths.large.mobile
    : formInputWidths[size].mobile}px;
    background-position-x: ${({ isMobileLarge, size }) => isMobileLarge
    ? formInputWidths.large.mobile - 8
    : formInputWidths[size].mobile - 8}px;
    ${({ compact }) => compact && 'margin-right: -10px;'}
  }
`;

const MoreInfos = styled.p`
  ${(props) => props.noDisplay && 'display: none;'}
  font-family: Roboto;
  line-height: 24px;
  font-size: 12px;
  width: 373px;
  margin-top: -10px;
  margin-bottom: 18px;
  ${({ error, errorOn2Lines, compact }) => error && `
    color: ${colors.red};
    width: 145px;
    margin-top: ${errorOn2Lines ? '20%' : '28%'};
    margin-left: -6px;
    ${compact && 'margin-top: -20px; margin-bottom: 0px; margin-left: 0px; width: 100%;'}
    `}
  ${({ topInfo }) => topInfo && `
    width: auto;
    margin: -21px 0px 14px;
  `}
  ${({ compact, small }) => !compact && small && 'width: 100px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 258px;
    ${({ error, errorOn2Lines, compact }) => error && `
      width: ${errorOn2Lines ? '200px' : '135px'};
      margin-top: -15px;
      ${compact && 'width: 275px; margin-top: -20px;'}
      margin-left: 0px;
    `}
    ${({ topInfo }) => topInfo && `
      width: auto;
      margin-top: -5px;
    `}
  }
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const InputAndImgContainer = styled.div`
  display: flex;
  @media (max-width: ${mobileThresholdPixels}) {
    ${(props) => props.column && 'flex-direction: column;'}
  }
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  width: 10px;
  height: 10px;
  position: relative;
  left: -30px;
  top: 15px;
`;

const SpinnerContainer = styled.div`
    display: flex;

    @media (max-width: ${mobileThresholdPixels}) {
      margin: -10px 0px 16px;
    }
`;

const SpinnerText = styled.p`
  font-size: 12px;
  line-height: 24px;
  height: min-content;
  margin: 10px 5px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: -8px 5px 0px 0px;
  }
`;

const renderSpinnerContainer = () => (
  <SpinnerContainer>
    <SpinnerText>Veuillez patienter</SpinnerText>
    <Spinner />
  </SpinnerContainer>
);

const CustomerForm = ({
  inputsList, listName, checkFormInputValue, setFormInputValue, isLoginLoading, compact,
}) => (
  <FormContainer compact={compact}>
    {Object.values(inputsList).map((
      {
        name, label, isActive, value, type, size, isMobileLarge, error, more, withSpinner, withDefaultProps,
      },
      index,
    ) => {
      if (name.includes('error')) {
        return (
          <FormInputContainer key={label} compact={compact}>
            {!withDefaultProps
              ? (
                <MoreInfos
                  error
                  errorOn2Lines={label.length > 20}
                  topInfo={index === 0}
                  noDisplay={!isActive}
                  small={name === 'errorEmail' && Object.values(inputsList).length === 4}
                  compact={compact}
                >
                  {label}
                </MoreInfos>
              )
              : <MoreInfos noDisplay={!isActive}>{label}</MoreInfos>}
          </FormInputContainer>
        );
      }
      return (
        <FormInputContainer key={name} compact={compact}>
          <Label>{label}</Label>
          <InputAndImgContainer column={withSpinner}>
            <SubContainer>
              <FormInput
                name={name}
                value={value}
                type={type || 'text'}
                size={size}
                isMobileLarge={isMobileLarge}
                onBlur={() => checkFormInputValue(name, listName)}
                onChange={({ target }) => setFormInputValue(target, listName)}
                error={error}
                compact={compact}
              />
              {error !== undefined && <I error={error} />}
            </SubContainer>
            {withSpinner && isLoginLoading && renderSpinnerContainer()}
          </InputAndImgContainer>
          {more && <MoreInfos>{more}</MoreInfos>}
        </FormInputContainer>
      );
    })}
  </FormContainer>
);

CustomerForm.propTypes = {
  inputsList: PropTypes.shape({}).isRequired,
  listName: PropTypes.string,
  isLoginLoading: PropTypes.bool,
  checkFormInputValue: PropTypes.func.isRequired,
  setFormInputValue: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

CustomerForm.defaultProps = {
  listName: undefined,
  compact: false,
  isLoginLoading: false,
};

export default CustomerForm;
