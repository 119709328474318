// cSpell:ignore tilly, tili

import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { isTablet } from 'react-device-detect';

import withAppContext from '../../withAppContext';
import withCustomerContext from '../../withCustomerContext';
import withOrderContext from '../../withOrderContext';
import routesMap from '../../Routes';
import { removeToken } from '../../services/api';
import DropdownList from './DropdownList';
import MobileMenu from './v3/MobileMenu';
import {
  colors, fontSizes, mobileThresholdPixels, Row, Button as StyledButton,
  menuBarHeightPixels, mobileMenuBarHeightPixels, menuBarHeightWithBannerPixels, mobileMenuBarHeightWithBannerPixels,
} from './v3/styledComponents';
import PopupLogin from '../MyAccount/PopupLogin';

import dropdownArrow from '../../assets/arrow-dropdown-list.svg';
import logoTilli from '../../assets/logos/tilli.navy.svg';
import menuIcon from '../../assets/menu.svg';
import closeIcon from '../../assets/menuClose.svg';
import happyIcon from '../../assets/home/happy.png';
import CTALink from '../CTALink';
import { getServicesList, partnersList, inspirationsList } from './menuData';

const BarContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: ${({ hasBanner }) => hasBanner ? menuBarHeightWithBannerPixels : menuBarHeightPixels};
  z-index: 3;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  @media (max-width: ${mobileThresholdPixels}) {
    background-color: ${colors.white};
    height: ${({ hasBanner }) => hasBanner ? mobileMenuBarHeightWithBannerPixels : mobileMenuBarHeightPixels};
    border-bottom-width: 0px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: ${menuBarHeightPixels};
`;

const StyledRow = styled(Row)`
  width: 100%;
  padding: 0px 23px;
  box-sizing: border-box;
  align-items: center;
  height: ${menuBarHeightPixels};
  @media (max-width: ${mobileThresholdPixels}) {
    height: ${mobileMenuBarHeightPixels};
  }
`;

const TextLink = styled.div`
  width: max-content;
  color: ${({ isYellow }) => isYellow ? colors.yellow : colors.navy};
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  padding: 0px ${({ noTabletPaddingRight }) => (noTabletPaddingRight && isTablet) ? 0 : 76}px 0px 0px;
  ${(props) => props.middle && 'padding: 0px'};
  flex: 1;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${({ noMobilePaddingTop }) => noMobilePaddingTop ? 0 : 5}px 0px 0px;
    color: ${colors.navy};
    font-size: ${fontSizes.s};
    text-align: center;
    &:hover {
      opacity: 1;
    }
  }
`;

const LinkContainer = styled.div`
  text-align: center;
  ${(props) => props.logo && 'margin: auto 0px;'}
  height: 100%;
  position: relative;

  @media (max-width: ${mobileThresholdPixels}) {
    ${(props) => props.logo && 'margin: 0px;'}
    margin-top: 0px;
  }
`;

const LeftPart = styled.div`
  position: absolute;
  left: 75px;
  display: flex;
  flex-direction: row;
`;

const RightPart = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const LogoImg = styled.img`
  width: 160px;
`;

const MenuIcon = styled.img`
  width: 22px;
`;

const Img = styled.img`
  padding-bottom: 1px;
  margin-left: 10px;
  ${(props) => props.rotate && 'transform: rotate(180deg)'};
`;

const StyledLink = styled(Link)`
  flex: 1;
  text-align: center;
  text-decoration: none;
`;

const Banner = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  ${({ center }) => center && 'justify-content: center;'}
  align-items: center;
  background-color: ${colors.lightGrey};
  padding-top: ${({ center }) => center ? 26 : 20}px;
  padding-bottom: 20px;
  top: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: ${({ center }) => center ? 10 : 6}px;
    padding-bottom: 6px;
    ${({ center }) => !center && 'flex-direction: column;'}
  }
`;

const GiftBanner = styled(Banner)`
  filter: drop-shadow(0px 4px 5px ${colors.shadowGiftBanner});
  @media (max-width: ${mobileThresholdPixels}) {
    padding-left: 10px;
    flex-direction: row;
  }
`;

const BannerText = styled.div`
  color: ${colors.navy};
  font-family: Roboto;
  font-weight: 500;
  font-size: ${fontSizes.s};
  text-align: center;
  text-transform: uppercase;
`;

const GiftBannerText = styled.div`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 18px;
  flex-grow: 1;
  text-align: center;
  min-width: 100vw;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 15px;
    min-width: unset;
  }
`;

const Icon = styled.img`
  width: 19px;
  height: 19px;
  margin-left: 5px;
  margin-bottom: -3px;
`;

const Button = styled(StyledButton)`
  min-width: 86px;
  min-height: 35px;
  position: absolute;
  left: 85vw;
  font-size: 15px;
  line-height: 19px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    align-self: center;
    min-width: 86px;
    min-height: 35px;
    position: unset;
  }
`;

const GiftButton = styled(Button)`
  position: static;
`;

const A = styled.a`
  position: relative;
  right: 10vw;
  @media (max-width: ${mobileThresholdPixels}) {
    align-self: center;
    position: unset;
  }
`;

const ItemContainer = styled.div`
  height: 100%;
`;

const renderLeftPart = () => (
  <LeftPart>
    <LinkContainer logo>
      <StyledLink to={routesMap.HomePage.url}>
        <TextLink middle>
          <LogoImg src={logoTilli} alt="Logo du service de couturière Tilli " />
        </TextLink>
      </StyledLink>
    </LinkContainer>
  </LeftPart>
);

const renderCTA = () => (
  <LinkContainer>
    <CTALink fromInteraction="MenuBar">
      <TextLink isYellow>Prendre rendez-vous</TextLink>
    </CTALink>
  </LinkContainer>
);

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    const { isLoginPopupOpened } = this.props;
    this.state = {
      displayServicesList: false,
      displayPartnersList: false,
      displayMyAccountList: false,
      displayMobileMenuList: false,
      isLoginPopupOpened,
    };
    this.setIsLoginPopupOpened = this.setIsLoginPopupOpened.bind(this);
    this.getMyAccountList = this.getMyAccountList.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isLoginPopupOpened } = this.props;
    if (isLoginPopupOpened !== prevProps.isLoginPopupOpened) {
      this.setIsLoginPopupOpened(isLoginPopupOpened);
    }
    return null;
  }

  setIsLoginPopupOpened() {
    const { isLoginPopupOpened } = this.state;
    this.setState({ isLoginPopupOpened: !isLoginPopupOpened });
  }

  getMyAccountList() {
    const { customerContext: { setSelectedOrder, initializeCustomerContext } } = this.props;
    return ([
      { text: 'Récapitulatif', url: routesMap.MyAccount.url },
      { text: 'Mes informations', url: routesMap.MyInfos.url },
      { text: 'Mes adresses & paiements', url: routesMap.MyAddressesAndPayments.url },
      {
        text: 'Mes commandes',
        url: routesMap.MyOrders.url,
        onClick: () => {
          this.setState({ displayMyAccountList: false });
          setSelectedOrder(undefined);
        },
      },
      {
        text: 'Déconnexion',
        url: routesMap.HomePage.url,
        onClick: () => {
          initializeCustomerContext();
          removeToken();
        },
      },
    ]);
  }

  toggleMobileMenuList() {
    const { displayMobileMenuList } = this.state;
    this.setState({ displayMobileMenuList: !displayMobileMenuList });
  }

  renderServicesList = () => {
    const { displayServicesList } = this.state;
    const { orderContext: { getOrderRouteAndEvent } } = this.props;
    const { orderRoute, restart } = getOrderRouteAndEvent();
    const servicesList = getServicesList(orderRoute, restart);
    return (
      <LinkContainer
        onMouseEnter={() => this.setState({ displayServicesList: true })}
        onMouseLeave={() => this.setState({ displayServicesList: false })}
      >
        <ItemContainer>
          <DropdownList isDisplayed={displayServicesList} dropdownList={servicesList} />
          <TextLink>
            Le service
            <Img
              src={dropdownArrow}
              rotate={displayServicesList ? 'true' : undefined}
              alt="Menu déroulant"
            />
          </TextLink>
        </ItemContainer>
      </LinkContainer>
    );
  }

  renderPricings = () => (
    <LinkContainer>
      <StyledLink to={routesMap.PricingsPage.url}>
        <TextLink>Grille des tarifs</TextLink>
      </StyledLink>
    </LinkContainer>
  )

  renderPartnersList = () => {
    const { displayPartnersList } = this.state;
    return (
      <LinkContainer
        onMouseEnter={() => this.setState({ displayPartnersList: true })}
        onMouseLeave={() => this.setState({ displayPartnersList: false })}
      >
        <ItemContainer>
          <DropdownList isDisplayed={!!displayPartnersList} dropdownList={partnersList} />
          <TextLink onMouseDown={() => navigate(routesMap.CollabPage.url)}>
            Collabs
            <Img
              src={dropdownArrow}
              rotate={displayPartnersList ? 'true' : undefined}
              alt="Les partenaires Tilly"
            />
          </TextLink>
        </ItemContainer>
      </LinkContainer>
    );
  }

  renderInspirationsList = () => {
    const { displayInspirationsList } = this.state;
    return (
      <LinkContainer
        onMouseEnter={() => this.setState({ displayInspirationsList: true })}
        onMouseLeave={() => this.setState({ displayInspirationsList: false })}
      >
        <ItemContainer>
          <DropdownList isDisplayed={!!displayInspirationsList} dropdownList={inspirationsList} />
          <TextLink onMouseDown={() => navigate(routesMap.SustainableFashionPage.url)}>
            Blog
            <Img
              src={dropdownArrow}
              rotate={displayInspirationsList ? 'true' : undefined}
              alt="Nos inspirations upcycling retouche transformation broderie"
            />
          </TextLink>
        </ItemContainer>
      </LinkContainer>
    );
  }

  renderMyAccountList = () => {
    const { displayMyAccountList, displayPartnersList } = this.state;
    const { customerContext: { customer } } = this.props;
    if (!customer || customer.isShadowAccount) {
      return (
        <LinkContainer>
          <TextLink onClick={() => this.setIsLoginPopupOpened(true)}>Connexion</TextLink>
        </LinkContainer>
      );
    }
    return (
      <LinkContainer
        onMouseEnter={() => this.setState({ displayMyAccountList: true })}
        onMouseLeave={() => this.setState({ displayMyAccountList: false })}
      >
        <ItemContainer>
          <DropdownList isDisplayed={!!displayMyAccountList} dropdownList={this.getMyAccountList()} />
          <TextLink>
            Mon compte
            <Img
              src={dropdownArrow}
              rotate={displayPartnersList ? 'true' : undefined}
              alt="Menu déroulant"
            />
          </TextLink>
        </ItemContainer>
      </LinkContainer>
    );
  }

  renderDesktop() {
    return (
      <Content>
        {renderLeftPart()}
        <RightPart>
          {this.renderServicesList()}
          {this.renderPricings()}
          {this.renderPartnersList()}
          {this.renderInspirationsList()}
          {this.renderMyAccountList()}
          {renderCTA()}
        </RightPart>
      </Content>
    );
  }

  renderMobile() {
    const { displayMobileMenuList } = this.state;
    return (
      <StyledRow justifyContent="space-between">
        <LinkContainer logo>
          <StyledLink to={routesMap.HomePage.url}>
            <TextLink noMobilePaddingTop>
              <LogoImg src={logoTilli} alt="Logo du service de couturière Tilli " />
            </TextLink>
          </StyledLink>
        </LinkContainer>
        <LinkContainer>
          <TextLink onMouseDown={() => this.toggleMobileMenuList()} noMobilePaddingTop noTabletPaddingRight>
            <MenuIcon
              alt="Tili couturière Paris"
              src={displayMobileMenuList ? closeIcon : menuIcon}
            />
          </TextLink>
        </LinkContainer>
      </StyledRow>
    );
  }

  renderBanner() {
    const {
      context: { isMobile },
      showBannerClacDesDoigts,
      showGiftBanner,
    } = this.props;

    const { displayMobileMenuList } = this.state;
    if ((isMobile || isTablet) && displayMobileMenuList) return null;
    if (showBannerClacDesDoigts) {
      return (
        <Banner center>
          <BannerText>
            t’as gagné 10 euros en un claquement de doigt !
            <Icon src={happyIcon} alt="Promo clac des doigts" />
          </BannerText>
        </Banner>
      );
    }
    if (showGiftBanner) {
      return (
        <GiftBanner>
          <GiftBannerText>
            Un cadeau personnalisé à offrir
          </GiftBannerText>
          <A href="https://cadeau.tilli.fr" target="_blank" rel="noopener">
            <GiftButton navy>Commander</GiftButton>
          </A>
        </GiftBanner>
      );
    }
    return null;
  }

  render() {
    const {
      context: { isMobile },
      customerContext: { customer },
      showBannerClacDesDoigts,
      showGiftBanner,
    } = this.props;

    const { displayMobileMenuList, isLoginPopupOpened } = this.state;
    const hasBanner = showBannerClacDesDoigts || showGiftBanner;
    return (
      <div>
        <BarContainer
          displayMobileMenuList={displayMobileMenuList}
          hasBanner={hasBanner && !displayMobileMenuList}
        >
          {(!isMobile && !isTablet) ? this.renderDesktop() : this.renderMobile()}
          {this.renderBanner()}
        </BarContainer>
        {(isMobile || isTablet) && displayMobileMenuList
          && (
            <MobileMenu
              openLoginPopup={() => this.setIsLoginPopupOpened(true)}
              onClick={() => this.toggleMobileMenuList()}
              isCustomerLoggedIn={!!customer}
              close={() => this.setState({ displayMobileMenuList: false })}
            />
          )}
        {isLoginPopupOpened
          && (
            <div>
              <PopupLogin closePopup={() => this.setIsLoginPopupOpened(false)} />
            </div>
          )}
      </div>
    );
  }
}

MenuBar.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      isShadowAccount: PropTypes.bool,
    }),
    setSelectedOrder: PropTypes.func,
    initializeCustomerContext: PropTypes.func,
  }).isRequired,
  isLoginPopupOpened: PropTypes.bool,
  showBannerClacDesDoigts: PropTypes.bool,
  showGiftBanner: PropTypes.bool,
  orderContext: PropTypes.shape({
    getOrderRouteAndEvent: PropTypes.func,
  }).isRequired,
};

MenuBar.defaultProps = {
  isLoginPopupOpened: false,
  showBannerClacDesDoigts: false,
  showGiftBanner: false,
};

export default withAppContext(withCustomerContext(withOrderContext(MenuBar)));
