import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { fontSizes } from './v2/styledComponents';

const Ul = styled.ul`
  display: ${(props) => props.isDisplayed ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: fit-content;
  padding: 20px ${({ theme }) => theme.margins.s};
  margin: 55px 0px 0px -14px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.navy};
  z-index: 5;
  text-align: left;
  overflow:hidden;
  list-style-type: none;
  border-width: thin;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lightGrey};
  right: 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.navy};
    top: 0px;
    min-height: calc(100vh - ${({ theme }) => theme.menuBarHeightPixels});
    width: 100vw;
    margin: 0px;
    padding: 20px 50px;
    border-width: 1px 0px 0px 0px;
    top: ${({ theme }) => theme.mobileMenuBarHeightPixels};
    left: 0px;
    right: 0px;
    z-index: 2;
    overflow: scroll;
  }
`;

const Li = styled.li`
  padding 8px 0px;
  &:hover {
    opacity: 0.7;
  }
`;

const Span = styled.span`
  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 17px;
    text-align: center;
  }
`;

const DropdownList = ({
  dropdownList, isDisplayed,
}) => (
  <Ul isDisplayed={isDisplayed}>
    {dropdownList.map(({ url, onClick, text }) => (
      <Li key={text}>
        {!!url
          && (
            <Link
              to={url}
              style={{ textDecoration: 'none', color: 'inherit' }}
              onClick={() => (typeof onClick === 'function') && onClick()}
            >
              <Span>{text}</Span>
            </Link>
          )}
      </Li>
    ))}
  </Ul>
);

DropdownList.propTypes = {
  dropdownList: PropTypes.arrayOf(PropTypes.shape({})),
  isDisplayed: PropTypes.bool,
};

DropdownList.defaultProps = {
  dropdownList: [],
  isDisplayed: false,
};

export default DropdownList;
