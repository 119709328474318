// cSpell:ignore itemreviewed

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import withAppContext from '../../../withAppContext';
import withOrderContext from '../../../withOrderContext';
import dropdownArrow from '../../../assets/arrow-dropdown-list.white.svg';
import routesMap from '../../../Routes';
import {
  colors, fontWeight, margins, mobileThresholdPixels,
  ContainerFooter, Content, LineV,
}
  from '../v2/styledComponents';
import { fontSizes } from './styledComponents';
import LeftBlock from './FooterLeftBlock';
import FooterMobileAndSocial from './FooterMobileAndSocial';
import logoTilli from '../../../assets/logos/tilli.white.svg';
import contactInfos from '../../../config/contactInfos';
import { pushOrderStartToGTM } from '../../CTALink';

import { ratingData } from './ratingData';

const getFooterContent = (orderRoute, restart) => ([
  {
    index: 0,
    title: 'Info & Aide',
    links: [
      {
        text: 'Prendre rendez-vous',
        url: orderRoute,
        onClick: () => pushOrderStartToGTM(orderRoute, 'Footer', restart),
      },
      { text: 'Grille des tarifs', url: routesMap.PricingsPage.url },
      { text: 'Aide', url: routesMap.FAQPage.url },
      { text: 'Comment ça marche', url: routesMap.HowItWorksPage.url },
    ],
  },
  {
    index: 1,
    title: 'Services',
    links: [
      { text: 'Retouche de vêtements', url: '/retouche-vetement/' },
      { text: 'Retouche de rideaux', url: '/retouche-rideaux/' },
      { text: 'Offre linge de maison', url: '/creation-maison/' },
      { text: 'Transformation', url: '/la-rubrique/transformation/' },
      { text: 'Broderie', url: '/la-rubrique/personnalisation/broderie/' },
    ],
  },
  {
    index: 2,
    title: 'Localisations',
    links: [
      { text: 'Retouches Paris', url: '/retouche-vetement/paris/' },
      { text: 'Retouches Lyon', url: '/retouche-vetement/lyon/' },
      { text: 'Retouches Marseille', url: '/retouche-vetement/marseille/' },
      { text: 'Retouches Bordeaux', url: '/retouche-vetement/bordeaux/' },
      { text: 'Retouches Toulouse', url: '/retouche-vetement/toulouse/' },
    ],
  },
  {
    index: 3,
    title: 'Univers',
    links: [
      { text: 'Histoire', url: routesMap.AboutPage.url },
      { text: 'Inspirations', url: routesMap.LaRubrique.url },
      { text: 'Nos partenaires', url: routesMap.PartnersPage.url },
      { text: 'Nos Tillistes', url: routesMap.OurArtisansPage.url },
      { text: 'Devenir Tilliste', url: routesMap.BeTillistePage.url },
    ],
  },
]);

const StyledContainerFooter = styled(ContainerFooter)`
  display: flex;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 90px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 30px;
    padding-bottom: 0px;
  }
`;

const StyledContent = styled(Content)`
  padding: ${margins.x2l} 0px;
  background-color: ${colors.navy};
  margin: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: auto;
    width: auto;
  }
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
    margin: 0px 42px;
  }
`;

const TextMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  margin: 38px 0px 47px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 10px;
  }
`;

const BlockSuperContainer = styled.div`
  text-align: initial;
`;

const BlockContainer = styled.div`
  font-size: ${fontSizes.s};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin : ${(props) => !props.last ? '0px 108px 0px 0px' : `0px  0px 0px ${margins.s}`};
  text-align: initial;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 13px;
    margin: 0px;
    align-items: center;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-family: Libre Baskerville;
  font-weight: ${fontWeight.medium};
  line-height: ${fontSizes.l};
  font-size: 26px;
  color: ${colors.white};
  margin-top: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 13px;
    margin: 0px;
    align-items: center;
    font-size: ${fontSizes.m};
  }
`;

const TextContainer = styled.div`
  color: white;
  font-family: Roboto;
  font-size: ${fontSizes.s};
  font-weight: ${fontWeight.light};
  line-height: 24px;
  ${(props) => props.pointer && 'cursor: pointer'};
  text-align: left;

  @media (max-width: ${mobileThresholdPixels}) {
    font-weight: ${fontWeight.regular};
    ${(props) => props.link && `padding-bottom: ${margins.s};`}
    ${(props) => props.centered && 'text-align: center'}
  }
`;

const BlockTitle = styled.div`
  color: white;
  font-family: Libre Baskerville;
  font-size: ${fontSizes.m};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 30px;
  margin-bottom: 6px;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    ${(props) => props.link && `padding-top: ${margins.s};`}
  }
`;

const LinkText = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 14px;
  font-weight: ${fontWeight.light};
  line-height: 24px;
  cursor: ${(props) => props.noCursor ? 'auto' : 'pointer'};

  @media (max-width: ${mobileThresholdPixels}) {
    font-weight: ${fontWeight.regular};
    font-size: 12px;
    line-height: 20px;
    ${(props) => props.link && `padding-top: ${margins.s};`}
  }
`;

const LinkTextInline = LinkText.withComponent('span');

const MenusContainer = styled.div`
  border-color: ${colors.white};
  border-style: solid;
  border-width: 1px 0px;
  margin: ${margins.m} auto 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 15px 0px;
    margin: 0px 13px 30px 13px;
  }
`;

const MenuContainer = styled.div`
  margin: ${margins.m} auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const MenuTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MenuTitle = styled.h3`
  margin: 5px 0px;
  color: white;
  font-family: Libre Baskerville;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSizes.s};
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
`;

const Img = styled.img`
  padding-bottom: 1px;
  margin-left: 4px;
  width: 10px;
  ${(props) => props.rotate && 'transform: rotate(180deg)'};
`;

const LogoImg = styled.img`
  width: 252px;
  margin-bottom: 86px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100% - 26px);
    margin: ${margins.m} 13px 30px;
  }
`;

const StyledLineV = styled(LineV)`
  align-self: stretch;
  height: unset;
  max-height: 315px;
`;

const LegalContainer = styled.div`
  text-align: left;
  margin-right: 83px;
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    padding: 20px 20px 0px;
    margin-right: 0px;
  }
`;

const ContactTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 20px;
    font-size: 14px;
  }
`;

const ContactText = styled.p`
  font-family: Roboto;
  line-height: ${fontSizes.l};
  font-size: 18px;
  color: ${colors.white};
  text-align: left;
  margin-bottom: 62px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 13px;
    line-height: 20px;
    font-size: 13px;
    margin-bottom: 50px;
  }
`;

const StyledDiv = styled.div`
  margin-left: 40px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    max-width: 326px;
    margin-left: 0px;
  }
`;

const StyledBottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Contact = (
  <ContactText>
    <ContactTitle>
      Pour toutes questions, contactez-nous
    </ContactTitle>
    <br />
    par mail : contact@tilli.fr ou par téléphone au
    {' '}
    {contactInfos.phoneNumber}
  </ContactText>
);

export const renderRating = (
  <LinkTextInline centered noCursor>
    <LinkTextInline
      noCursor
      itemScope
      itemType="http://schema.org/LocalBusiness"
    >
      <LinkTextInline
        noCursor
        itemScope
        itemType="http://data-vocabulary.org/Review-aggregate"
      >
        <LinkTextInline itemProp="itemreviewed" noCursor>Tilli.fr</LinkTextInline>
        {' a reçu la note de '}
        <LinkTextInline
          noCursor
          itemProp="rating"
          itemScope
          itemType="http://data-vocabulary.org/rating"
        >
          <LinkTextInline itemProp="average" noCursor>{ratingData.scoring}</LinkTextInline>
          {' / '}
          <LinkTextInline itemProp="best" noCursor>10</LinkTextInline>
        </LinkTextInline>
        {' '}
        sur
        {' '}
        <LinkTextInline itemProp="votes" noCursor>{ratingData.numberOfReviews}</LinkTextInline>
        {' '}
        avis.
      </LinkTextInline>
    </LinkTextInline>
  </LinkTextInline>
);

const renderLegal = (
  <LegalContainer>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={routesMap.Insurance.url}
    >
      <LinkTextInline>Notre assurance MMA</LinkTextInline>
    </Link>
    <LinkTextInline>{' | '}</LinkTextInline>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={routesMap.LegalTerms.url}
    >
      <LinkTextInline>CGV/CGU</LinkTextInline>
    </Link>
    <LinkTextInline>{' | Tous droits réservés'}</LinkTextInline>
  </LegalContainer>
);

class Footer1 extends React.Component {
  constructor() {
    super();
    this.state = { openedIndex: -1 };
  }

  renderDesktop(footerContent) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {footerContent.map((content) => (
          <BlockSuperContainer key={`${content.index}_desktop`}>
            <BlockContainer last={content.index === (footerContent.length - 1)}>
              <BlockTitle>{content.title}</BlockTitle>
              {content.links.map((link) => (
                <Link
                  key={link.url}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  to={link.url}
                  onClick={() => typeof link.onClick === 'function' && link.onClick()}
                >
                  <LinkText>{link.text}</LinkText>
                  {link.emptyLineNext && <br />}
                </Link>
              ))}
              {content.contactInfos
                && (
                  <TextContainer>
                    <br />
                    <br />
                    contact@tilli.fr
                    <br />
                    {contactInfos.phoneNumber}
                  </TextContainer>
                )}
              {content.showRating && renderRating}
            </BlockContainer>
          </BlockSuperContainer>
        ))}
      </>
    );
  }

  renderMobile(footerContent) {
    const { openedIndex } = this.state;
    return (
      <div>
        <ContactText>
          <ContactTitle>
            Pour toutes questions, contactez-nous
          </ContactTitle>
          <br />
          par mail : contact@tilli.fr
          <br />
          ou par téléphone au
          {' '}
          {contactInfos.phoneNumber}
        </ContactText>
        <MenusContainer>
          {footerContent.map((content, index) => (
            <MenuContainer key={`${content.title}_mobile`}>
              <MenuTitleContainer onClick={() => this.setState({ openedIndex: openedIndex === index ? -1 : index })}>
                <MenuTitle>{content.title}</MenuTitle>
                <Img
                  alt="Flèche menu déroulant"
                  src={dropdownArrow}
                  rotate={(openedIndex === index) ? 'true' : undefined}
                />
              </MenuTitleContainer>
              {(openedIndex === index) && content.links.map((link) => (
                <Link
                  key={link.url}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  to={link.url}
                >
                  <TextContainer link>{link.text}</TextContainer>
                </Link>
              ))}
            </MenuContainer>
          ))}
        </MenusContainer>
        <FooterMobileAndSocial />
        {renderRating}
        {renderLegal}
      </div>
    );
  }

  render() {
    const { context: { isMobile } } = this.props;
    const { orderContext: { getOrderRouteAndEvent } } = this.props;
    const { orderRoute, restart } = getOrderRouteAndEvent();
    const footerContent = getFooterContent(orderRoute, restart);
    return (
      <StyledContainerFooter>
        <StyledContent>
          <SubContainer>
            {!isMobile && <LeftBlock />}
            {!isMobile
              && <StyledLineV color={colors.white} />}
            <StyledDiv>
              {isMobile && <LogoImg src={logoTilli} alt="logo Tilli" />}
              <Title>Simplifiez-vous l’habit</Title>
              <TextMainContainer>
                {!isMobile ? this.renderDesktop(footerContent) : this.renderMobile(footerContent)}
              </TextMainContainer>
              {!isMobile && Contact}
              <StyledBottomDiv>
                {!isMobile && renderLegal}
                {!isMobile && renderRating}
              </StyledBottomDiv>
            </StyledDiv>
          </SubContainer>
        </StyledContent>
      </StyledContainerFooter>
    );
  }
}

Footer1.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  orderContext: PropTypes.shape({
    getOrderRouteAndEvent: PropTypes.func,
  }).isRequired,
};

export default withAppContext(withOrderContext(Footer1));
