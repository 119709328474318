import React from 'react';
import styled from 'styled-components';
import logoTilli from '../../../assets/logos/tilli.white.svg';
import { margins, mobileThresholdPixels }
  from '../v2/styledComponents';
import FooterMobileAndSocial from './FooterMobileAndSocial';

const LogoImg = styled.img`
  width: 252px;
  margin-bottom: 86px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin-top: ${margins.m};
    margin-bottom: 30px;
  }
`;

const LeftBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${mobileThresholdPixels}) {
    align-items: center;
  }
`;

const FooterLeftBlock = () => (
  <LeftBlockContainer>
    <LogoImg src={logoTilli} alt="logo Tilli" />
    <FooterMobileAndSocial />
  </LeftBlockContainer>
);

export default FooterLeftBlock;
